<template>
    <div class="contents">
        <h1 class="pagetitle">ご注文内容</h1>
        <template v-for="data in contents.listItems">
            <div v-if="Number(contents.orderCount[data.item_id])" :key="`item${data.item_id}`" class="item">
                <div class="item__contents--image">
                    <img class="item__contents__image" :src="`${filesUrl}${data.item_image1}`" alt="">
                </div>
                <div class="item__contents">
                    <h2 class="item__contents__title">
                        {{ data.item_name }}
                    </h2>
                    <p class="item__contents__price">
                        <span class="item__contents__price__tax">税込</span>{{ data.price | $_filterFormatPrice }}円
                    </p>
                    <div class="item__contents__order">
                        注文数：{{ Number(("0" + contents.orderCount[data.item_id]).slice(-2)) }}
                    </div>
                </div>
            </div>
        </template>
        <h1 class="l-mt9 pagetitle">お客様情報</h1>
        <table>
            <tr>
                <th>氏名</th>
            </tr>
            <tr>
                <td>
                    {{ contents.input.name }}
                </td>
            </tr>
            <tr>
                <th>電話番号</th>
            </tr>
            <tr>
                <td>
                    {{ contents.input.tel }}
                </td>
            </tr>
            <tr>
                <th>郵便番号</th>
            </tr>
            <tr>
                <td>
                    {{ contents.input.postal_code }}
                </td>
            </tr>
            <tr>
                <th>住所</th>
            </tr>
            <tr>
                <td>
                    {{ contents.input.address }}
                </td>
            </tr>
            <tr>
                <th>メールアドレス</th>
            </tr>
            <tr>
                <td>
                    {{ contents.input.mail_address }}
                </td>
            </tr>
            <tr>
                <th>コメント</th>
            </tr>
            <tr>
                <td>
                    {{ contents.input.comment }}
                </td>
            </tr>
            <tr>
                <th>支払方法</th>
            </tr>
            <tr>
                <td>
                    {{ contents.input.payment_method }}
                    <div v-show="contents.input.payment_method === '銀行振込（前払い）'" class="l-mt4">
                        代金前払いとなります。ご注文後にお振込ご案内メールをご登録メールアドレスへ送信いたします。<br>メール内容をご確認のうえお振込みください。
                    </div>
                    <div v-show="contents.input.payment_method === '代金引換'" class="l-mt4">
                        運送会社による代金引換にてお届けいたします。商品お届け時に運送会社ドライバーへお支払いください。
                    </div>
                </td>
            </tr>
            <tr>
                <th>領収証</th>
            </tr>
            <tr>
                <td>
                    {{ contents.input.receipt | $_filterFormatAvailability }}
                    <div v-if="contents.input.receipt === '1'" class="l-mt4">
                        <div>宛名：{{ contents.input.receipt_address }}</div>
                        <div class="l-mt1">但し書き：{{ contents.input.receipt_description }}</div>
                        <div class="l-mt1">納付先：{{ contents.input.receipt_include | $_filterFormatReceiptInclude }}</div>
                    </div>

                </td>
            </tr>
            <template v-if="!contents.input.client_id">
                <tr>
                    <th>アカウント登録</th>
                </tr>
                <tr>
                    <td>
                        {{ contents.input.account_registration | $_filterFormatAvailability }}
                    </td>
                </tr>
            </template>
        </table>
        <div v-if="contents.flagSendData">
            <h1 class="l-mt9 pagetitle">送り先情報</h1>
            <table>
                <tr>
                    <th>氏名</th>
                </tr>
                <tr>
                    <td>
                        {{ contents.input.send_name }}
                    </td>
                </tr>
                <tr>
                    <th>電話番号</th>
                </tr>
                <tr>
                    <td>
                        {{ contents.input.send_tel }}
                    </td>
                </tr>
                <tr>
                    <th>郵便番号</th>
                </tr>
                <tr>
                    <td>
                        {{ contents.input.send_postal_code }}
                    </td>
                </tr>
                <tr>
                    <th>住所</th>
                </tr>
                <tr>
                    <td>
                        {{ contents.input.send_address }}
                    </td>
                </tr>
            </table>
        </div>
        <h1 class="l-mt9 pagetitle">お支払金額</h1>
        <table>
            <tr>
                <th>小計</th>
                <td>
                    <span class="tax">税込</span>{{ contents.input.subtotal | $_filterFormatPrice }}円
                </td>
            </tr>
        </table>
        <table class="l-mt3">
            <tr>
                <th>送料</th>
                <td>
                    <span class="tax">税込</span>{{ contents.input.shipping_fee | $_filterFormatPrice }}円
                </td>
            </tr>
        </table>
        <table class="l-mt3">
            <tr>
                <th> 合計</th>
                <td>
                    <span class="price">
                        <span class="tax">税込</span>{{ contents.input.total | $_filterFormatPrice }}円
                    </span>
                </td>
            </tr>
        </table>
        <div class="l-mt10 l-mb8 is-center">
            <button v-if="!loader" :class="`${classButton}primary`" @click="handleCreate()">
                {{ buttonText }}
            </button>
            <Loader :loader="loader" />
        </div>
        <form name="paygent" method="POST" action="https://sandbox.paygent.co.jp/v/request">
            <input v-model="hidden.trading_id" type="hidden" name="trading_id" />
            <input v-model="hidden.id" type="hidden" name="id" />
            <input v-model="hidden.seq_merchant_id" type="hidden" name="seq_merchant_id" />
            <input v-model="hidden.return_url" type="hidden" name="return_url" />
            <input v-model="hidden.hc" type="hidden" name="hc" />
            <input v-model="hidden.merchant_name" type="hidden" name="merchant_name" />
            <input v-model="hidden.finish_disable" type="hidden" name="finish_disable" />
        </form>
        <div class="is-center">
            <button class="button--text-link" type="button" @click="$_goBack()">お客様情報入力へ戻る</button>
        </div>
    </div>
</template>

<script>
    "use strict";

    import Loader from "@/components/Loader.vue";
    import common from "@/mixins/common";
    import confirmSave from "@/mixins/confirmSave";
    import filter from "@/mixins/filter";

    export default {
        "components": {
            Loader,
        },
        "mixins": [
            common,
            confirmSave,
            filter,
        ],
        "props": {
            "contents": {
                "required": true,
                "type": Object,
            },
        },
        data() {
            return {
                "classButton": "button--oval--",
                "filesUrl": common.$_configs.filesUrl,
                "hashKey": "EjvqMsGz",
                "hidden": {
                    "trading_id": "",
                    "id": "",
                    "seq_merchant_id": "41262",
                    "return_url": "",
                    "hc": "",
                    "merchant_name": "",
                    "finish_disable": 1,
                },
                "loader": false,
            };
        },
        "computed": {
            buttonText() {
                // お支払い方法により、ボタンテキストを切り替え
                if (this.contents.input.payment_method === "クレジットカード") {
                    return "お支払い画面へ進む";
                } else {
                    return "注文する";
                }
            },
        },
        created() {
            if (!this.contents.input.order.length) {
                this.$router.push({ "name": "shop_list" });
            }
        },
        "methods": {
            hash() {
                // ペイジェント
                if (!crypto || !crypto.subtle) {
                    throw Error("crypto.subtle is not supported.");
                }

                const algo = "SHA-256";
                const str = `${this.hidden.trading_id}${this.hidden.id}${this.hidden.seq_merchant_id}${this.hashKey}`;

                const hexString = (buffer) => {
                    const byteArray = new Uint8Array(buffer);
                    const hexCodes = [...byteArray].map(value => {
                        const hexCode = value.toString(16);
                        const paddedHexCode = hexCode.padStart(2, "0");
                        return paddedHexCode;
                    });
                    return hexCodes.join("");
                };

                return crypto.subtle.digest(algo, new TextEncoder().encode(str)).then(x => {
                    const hex = hexString(x);
                    this.hidden.hc = hex;
                });
            },
            setUser() {
                // アカウント登録用データをset
                for (let key in this.contents.user) {
                    if (key in this.contents.input) {
                        this.contents.user[key] = this.contents.input[key];
                    }
                }
            },
            payment() {
                // 支払い方法によって、完了か支払いかを切り替える
                if (this.contents.input.payment_method === "クレジットカード") {
                    const process = [
                        this.hash(),
                    ];
                    Promise.all(process).then(() => {
                        window.removeEventListener("beforeunload", this.confirmSave);
                        document.paygent.submit();
                    });
                } else {
                    this.$router.push({ "name": "finish" });
                }
            },
            autoLogin() {
                // アカウント登録時の自動ログイン
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/login`,
                    {
                        "mail_address": this.contents.user.mail_address,
                        "password": this.contents.user.password,
                    }
                ).then(response => {
                    this.contents.input.client_id = response.data.client_id;
                    this.handleOrdersCreate();
                }).catch(() => {
                    alert("ログインに失敗しました。");
                    this.handleOrdersCreate();
                });
            },
            handleOrdersCreate() {
                // 個別注文データ作成
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/orders`,
                    this.contents.input,
                    this.$_requestConfig()
                ).then(response => {
                    this.hidden.trading_id = String(response.data.order_id);
                    this.hidden.id = String(this.contents.input.total);
                    this.hidden.return_url = `${common.$_configs.url}/finish?order_id=${response.data.order_id}`;
                    this.payment();
                }).catch(() => {
                    alert("注文に失敗しました。");
                    this.loader = false;
                });
            },
            handleAccountCreate() {
                this.setUser();
                // 個別顧客データ作成
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/clients`,
                    this.contents.user,
                    this.$_requestConfig()
                ).then(() => {
                    this.autoLogin();
                }).catch(() => {
                    alert("アカウント登録済みのメールアドレスになります。");
                    this.contents.input.account_registration = "0";
                    this.handleOrdersCreate();
                });
            },
            handleAccountUpdate() {
                this.setUser();
                // 個別顧客データ更新
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/clients/update/${this.contents.input.client_id}`,
                    this.contents.user,
                    this.$_requestConfig()
                ).then(() => {
                    this.handleOrdersCreate();
                }).catch(() => {
                    alert("アカウントの更新に失敗しました。");
                    this.handleOrdersCreate();
                });
            },
            handleCreate() {
                this.loader = true;
                if (this.contents.input.account_registration === "1") {
                    this.handleAccountCreate();
                } else if (this.contents.flagUserUpdate) {
                    this.handleAccountUpdate();
                } else {
                    this.handleOrdersCreate();
                }
            },
        },
    };
</script>
